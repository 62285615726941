import { Button } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { addConfig } from '../services/store/slice/configSlice'
import { getConfigFromAttribubte } from '../services/api/queries'
import { useAppSelector } from '../services/store/store'
import { useGetModelsQuery } from '../services/api/api-model'

interface LaunchButtonProps {
    onClick?(): void
}

export default function LaunchButton(props: LaunchButtonProps) {
    const dispatch = useDispatch()

    const [clicked, setClicked] = useState<boolean>(false)

    const config = useAppSelector((state) => state.config)
    const {
        data: modelResponse,
        isFetching,
        isError,
    } = useGetModelsQuery(config.config, { skip: !clicked })

    const { onClick } = props

    const handleOnClick = () => {
        const attributeConfig = getConfigFromAttribubte()

        // ---- We delete fields that we don't need in Query default params ----
        const keysToRemove = ['api_key', 'api_prefix']
        keysToRemove.forEach((paramKey: string) => {
            delete attributeConfig[paramKey]
        })

        // ---- We dispatch the config to the store ----
        dispatch(addConfig(attributeConfig))

        setClicked(true)
    }

    useEffect(() => {
        if (!isFetching && clicked && onClick) {
            onClick()
            setClicked(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFetching, clicked])

    return (
        <Button
            block
            className='button--outlined'
            onClick={handleOnClick}
            loading={clicked && ((!modelResponse?.items && !isError) || isFetching)}
        >
            Switch Model
        </Button>
    )
}
