import React, { useEffect, useState } from 'react'

import Slider from 'react-slick'

import { Model } from '../types/api-types'
import CardModel from './CardModel'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { calcMaxWidth } from '../utils/calc-max-width'

const LOOK_RATIO = 0.66

interface ArrowProps {
    className: string
    onClick(): void
}

interface CarouselModelProps {
    models: Model[]
}

const CarouselModel = (props: CarouselModelProps) => {
    const { models } = props

    const [cardWidth, setCardWidth] = useState<number>()
    const [carouselMaxWidth, setCarouselMaxWidth] = useState<number>()
    const [nbSlideShow, setNbSlideShow] = useState<number>(5)

    // ---- Setup resize listener for max width of image ----
    useEffect(() => {
        let interval = 0
        function handleResize() {
            const slideElem = document.getElementsByClassName('slick-slide')[0]
            const descriptionElems = document.getElementsByClassName('card-carousel--text')
            if (slideElem && descriptionElems && descriptionElems.length > 0) {
                clearInterval(interval)

                // ---- Getting the bigger description in all descriptions ----
                let biggerDescriptionHeight = 0
                for (let i = 0; i < descriptionElems.length; i++) {
                    const item = descriptionElems.item(i)
                    if (item && item.clientHeight > biggerDescriptionHeight) {
                        biggerDescriptionHeight = item.clientHeight
                    }
                }

                // ---- We get the correct slideToShow according to window size ----
                let currentNbSlideShow = nbSlideShow
                switch (true) {
                    case window.innerWidth > 1024:
                        currentNbSlideShow = 5
                        break
                    case window.innerWidth > 768:
                        currentNbSlideShow = 3
                        break
                    default:
                        currentNbSlideShow = 1
                }

                // ---- Max Width calc ----
                const maxWidth = calcMaxWidth(
                    (window.innerWidth -
                        (currentNbSlideShow >= models.length ? 32 : 16) *
                            Math.min(currentNbSlideShow, models.length)) /
                        Math.min(currentNbSlideShow, models.length),
                    0,
                    window.innerHeight - 32,
                    LOOK_RATIO,
                    // bonus padding for max height + carousel padding + dots + bottom card + card margin + title
                    43 + // title
                        (nbSlideShow >= models.length ? 8 : 32) + // dots
                        155 + // bottom except description
                        biggerDescriptionHeight, // description
                    0,
                    0
                )
                setCardWidth(maxWidth)

                // ---- We calculate the max width of the carousel according to currentNbSlideShow and cardWidth ----
                if (nbSlideShow !== currentNbSlideShow) {
                    setNbSlideShow(currentNbSlideShow)
                }
                const currentCarouselMaxWidth =
                    currentNbSlideShow === 1
                        ? window.innerWidth
                        : (maxWidth + (nbSlideShow >= models.length ? 32 : 16)) *
                              Math.min(currentNbSlideShow, models.length) +
                          32
                setCarouselMaxWidth(currentCarouselMaxWidth)
            }
        }

        interval = window.setInterval(() => {
            handleResize()
        }, 100)
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
        // eslint-disable-next-line
    }, [])

    const CarouselNextArrow = (arrowProps: ArrowProps) => (
        <div className={`carousel--arrow carousel--right`} onClick={arrowProps.onClick}>
            <RightOutlined />
        </div>
    )

    const CarouselPrevArrow = (arrowProps: ArrowProps) => (
        <div className={`carousel--arrow carousel--left`} onClick={arrowProps.onClick}>
            <LeftOutlined />
        </div>
    )

    const settings = {
        dots: nbSlideShow < models.length,
        initialSlide: 0,
        centerMode: true,
        centerPadding: nbSlideShow >= models.length ? '32px' : '16px',
        infinite: true,
        slidesToShow: Math.min(5, models.length),
        swipeToSlide: true,
        focusOnSelect: false,
        touchThreshold: 15,
        slidesToScroll: 1,
        speed: 200,
        arrow: true,
        //@ts-ignore
        nextArrow: <CarouselNextArrow />,
        //@ts-ignore
        prevArrow: <CarouselPrevArrow />,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: Math.min(3, models.length),
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 1680,
                settings: {
                    slidesToShow: Math.min(5, models.length),
                    slidesToScroll: 1,
                },
            },
        ],
    }

    return (
        <div
            style={
                carouselMaxWidth
                    ? {
                          maxWidth: carouselMaxWidth,
                          width: Math.min(
                              carouselMaxWidth,
                              window.innerWidth - (window.innerWidth <= 768 ? 0 : 32)
                          ),
                      }
                    : { width: '100%' }
            }
        >
            <Slider
                {...settings}
                className={`carousel--container${
                    nbSlideShow >= models.length ? ' carousel--container-no-swipe' : ''
                }`}
            >
                {models.length > 0 &&
                    models.map((item, itemKey: number) => (
                        <CardModel
                            model={item}
                            key={itemKey}
                            cardWidth={cardWidth}
                            ratio={100 / LOOK_RATIO}
                            enablePreview={true}
                        />
                    ))}
            </Slider>
        </div>
    )
}

export default CarouselModel
