import React from 'react'
import { useGetModelsQuery } from '../services/api/api-model'
import CarouselModel from './CarouselModel'
import { useAppSelector } from '../services/store/store'
import { getCloseIcon } from '../utils/icon'
import { Button } from 'antd'

interface ModalSwitchModelProps {
    closeModal(): void
}

export default function ModalSwitchModel(props: ModalSwitchModelProps) {
    const config = useAppSelector((state) => state.config)
    const { data: modelResponse } = useGetModelsQuery(config.config)

    const { closeModal } = props

    return (
        <div className='modal--container'>
            <div className='modal--header'>
                <h2>Switch Model {modelResponse ? `(${modelResponse?.items.length})` : ''}</h2>
                {
                    <Button
                        className='modal--close'
                        onClick={closeModal}
                        icon={getCloseIcon()}
                        type='link'
                    />
                }
            </div>

            <div className='modal--content'>
                {modelResponse?.items && <CarouselModel models={modelResponse.items} />}
            </div>
        </div>
    )
}
