import { combineReducers, configureStore } from '@reduxjs/toolkit'
// Or from '@reduxjs/toolkit/query/react'
import { setupListeners } from '@reduxjs/toolkit/query'
import { TypedUseSelectorHook, useSelector } from 'react-redux'
import { apiModel } from '../api/api-model'
import configSlice from './slice/configSlice'
import lookSlice from './slice/lookSlice'

const combinedReducer = combineReducers({
    [apiModel.reducerPath]: apiModel.reducer,
    config: configSlice,
    look: lookSlice,
})

export const store = configureStore({
    // Adding the api middleware enables caching, invalidation, polling,
    // and other useful features of `rtk-query`.
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiModel.middleware),

    reducer: combinedReducer,
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch

// Export the typed useSelector hook to use in react component
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch)
