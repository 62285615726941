import { Button, Card, Row, Typography } from 'antd'
import React, { useMemo, useState } from 'react'
import { Model } from '../types/api-types'
import { resizeImage } from '../utils/image'
import ImageSmooth from './ImageSmooth'
import FilterSelect from './filter'
import { useAppSelector } from '../services/store/store'
import { useDispatch } from 'react-redux'
import { changeImageIndex } from '../services/store/slice/lookSlice'
import { changeModalVisible } from '../services/store/slice/configSlice'

const { Title, Paragraph } = Typography

const sizes = ['32', '34', '36', '38', '40', '42', '44', '46', '48', '50', '52']

interface CardModelProps {
    model: Model
    cardWidth?: number
    ratio?: number
    enablePreview?: boolean
}

export default function CardModel(props: CardModelProps) {
    const { model, cardWidth, ratio, enablePreview } = props

    const dispatch = useDispatch()

    const lookImageIndex = useAppSelector((state) => state.look.imageIndex)

    const [sizeValue, setSizeValue] = useState<string>(model.model_garment_size2 || '')

    const modelImage = useMemo(() => {
        if (model.image_urls && model.image_urls.length >= lookImageIndex) {
            return model.image_urls[lookImageIndex]
        }

        return model.image_url
    }, [lookImageIndex, model])

    const handleSizeChange = (newSize: string) => {
        setSizeValue(newSize)
    }

    const handleChangeImage = () => {
        dispatch(changeImageIndex(lookImageIndex === 1 ? 0 : 1))
    }

    return (
        <Card
            className='card-carousel card-carousel--container override_card_container'
            hoverable={window.innerWidth > 768}
            cover={
                <div className='card card--image-container'>
                    <div className={'card--image'}>
                        <ImageSmooth
                            overflow={false}
                            ratio={ratio || 166}
                            src={resizeImage(modelImage, { width: 800 })}
                            loader={true}
                            cover={true}
                            enablePreview={enablePreview}
                            fullSizeSrc={modelImage}
                            changeImage={() => handleChangeImage()}
                        />
                    </div>
                </div>
            }
            style={cardWidth ? { width: cardWidth } : {}}
        >
            <Title
                ellipsis={{
                    rows: 1,
                }}
                className='card-carousel--title'
            >
                {model.model_display || model.model_name}
            </Title>
            <Paragraph
                ellipsis={{
                    rows: 3,
                    tooltip: true,
                }}
                className='card-carousel--text'
            >
                {model.model_description}
            </Paragraph>

            <Row align='middle' justify='center' style={{ marginBottom: '8px', width: '100%' }}>
                <FilterSelect
                    name='size'
                    placeholder={'Taille'}
                    options={sizes.map((size) => {
                        return {
                            label: size,
                            value: size,
                            extra:
                                size === model.model_garment_size2
                                    ? `Porté par ${model.model_name}`
                                    : '',
                        }
                    })}
                    customStyle={{ width: '100%' }}
                    onChange={handleSizeChange}
                    value={sizeValue}
                />
            </Row>
            <Row align='middle' justify='center' style={{ width: '100%' }}>
                <Button
                    type='primary'
                    style={{ width: '100%' }}
                    onClick={() => dispatch(changeModalVisible(false))}
                >
                    Add to cart
                </Button>
            </Row>
        </Card>
    )
}
