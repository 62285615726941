import { Select } from 'antd'
import React, { useEffect } from 'react'
import { ReactFitty } from 'react-fitty'

const { Option } = Select

interface OptionInterface {
    label: string
    value: string
    extra?: string
    in_stock?: boolean
    count?: string
}

interface SelectProps {
    placeholder: string
    options: OptionInterface[]
    translation?: boolean
    name: string
    value?: any
    onChange?(e: any, name: string): void
    defaultOpen?: boolean
    customStyle?: any
    isFacet?: boolean
}

const FilterSelect = (props: SelectProps) => {
    const {
        options,
        placeholder,
        name,
        value,
        defaultOpen = false,
        customStyle = {},
        isFacet,
        onChange,
    } = props

    useEffect(() => {
        if (value) {
            const filteredOptions = options.filter((one) => one.value === value)
            if (filteredOptions.length !== 1 && onChange) {
                onChange(null, name)
            }
        }
        // eslint-disable-next-line
    }, [options, value])

    const getPopupContainer = (trigger: HTMLElement) => {
        const closest = trigger.closest('.ant-layout-content') as HTMLElement
        return closest || document.body
    }

    const inStock = (option: OptionInterface) => {
        if (typeof option.in_stock !== 'undefined') {
            return !!option.in_stock
        }
        return true
    }

    const filteredOptions = options.filter((one) => one.value === value)

    return (
        <>
            <Select
                placeholder={placeholder ?? ''}
                className='select select--container'
                onClick={(e: any) => e.stopPropagation()}
                onChange={(e: any) => {
                    if (onChange) {
                        onChange(e, name)
                    }
                }}
                value={filteredOptions.length === 1 ? value : null}
                defaultOpen={defaultOpen}
                autoFocus={defaultOpen}
                getPopupContainer={getPopupContainer}
                style={customStyle}
                showAction={['focus', 'click']}
            >
                {options.map((option, optionKey: number) => (
                    <Option
                        className='select--option'
                        key={optionKey}
                        value={option.value}
                        disabled={!inStock(option)}
                    >
                        {!isFacet && (
                            <>
                                <div className='select--option-label'>{option.label}</div>

                                {option.extra && (
                                    <div className='select--option-extra'>
                                        <ReactFitty
                                            minSize={9}
                                            maxSize={12}
                                            style={{
                                                width: '100%',
                                                textAlign: 'right',
                                                verticalAlign: 'middle',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                            }}
                                        >
                                            {option.extra}
                                        </ReactFitty>
                                    </div>
                                )}
                            </>
                        )}
                        {option.count && (
                            <div>{`${option.label ? option.label : option.value} (${
                                option.count
                            })`}</div>
                        )}
                    </Option>
                ))}
            </Select>
        </>
    )
}

export default FilterSelect
