import { Modal } from 'antd'
import React, { useRef } from 'react'
import ModalSwitchModel from '../components/ModalSwitchModel'
import LaunchButton from '../components/LaunchButton'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../services/store/store'
import { changeModalVisible } from '../services/store/slice/configSlice'

export default function Main() {
    const dispatch = useDispatch()
    const modalVisible = useAppSelector((state) => state.config.modal.visible)
    const htmlOverflowRef: any = useRef()

    const openModal = () => {
        dispatch(changeModalVisible(true))
        const htmlElement = document.getElementsByTagName('html')[0]
        htmlOverflowRef.current = htmlElement.style.overflowY || null
        htmlElement.style.overflowY = 'hidden'
    }

    const closeModal = () => {
        dispatch(changeModalVisible(false))
        const htmlElement = document.getElementsByTagName('html')[0]
        htmlElement.style.overflowY = htmlOverflowRef.current || 'unset'
    }

    return (
        <>
            <Modal
                className='modal'
                destroyOnClose
                open={modalVisible}
                centered={true}
                onCancel={() => closeModal()}
                footer={null}
                closable={false}
            >
                <ModalSwitchModel closeModal={closeModal} />
            </Modal>
            <LaunchButton onClick={openModal} />
        </>
    )
}
